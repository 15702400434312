import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Link from '../link'
import { slugify } from "../../utils"

const ModuleBlogCard = ({ data, isHidden = false, isLarge = false }) => {
  const link = `${slugify(data.node_locale)}/blog${slugify(data.slug)}`
  return (
    <div className={`col-12 col-md-6 ${!isLarge && "col-lg-4"} d-flex`}>
      <Link to={link} className={`card mb-6 shadow-light-lg lift lift-lg ${isHidden && "d-lg-none"}`}>

        {data.keyVisual
          ? (
            <div className="card-img-top">
              <Img
                fluid={data.keyVisual.fluid}
                alt={data.title}
                className="card-img-top"
              />
              <div className="position-relative">
                <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
                  <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2160 0C1440 240 720 240 720 240H0V480H2880V0H2160Z" fill="currentColor" />
                  </svg>
                </div>
              </div>
            </div>
          )
          : (
            <div className="card-img-top pt-1 bg-gradient" />
          )
        }
        <div className="card-body">
          <h3 className="line-height-compact mb-4 font-size-h3">
            {data.title}
          </h3>
          <p className="mb-0 text-muted font-size-sm">
            {data.description}
          </p>
        </div>

        <div className="card-meta mt-auto">
          <hr className="card-meta-divider" />
          <div className="avatar avatar-sm mr-2">
            <Img fluid={data.author.profilePicture.fluid} alt={data.author.name} className="avatar-img rounded-circle" />
          </div>
          <h6 className="text-uppercase text-muted mr-2 mb-0">
            {data.author.name}
          </h6>
          <p className="h6 text-uppercase text-muted mb-0 ml-auto">
            <time dateTime={data.date}>{data[`date_${data.node_locale}`]}</time>
          </p>
        </div>
      </Link>
    </div>
  )
}

export const blogCardFragment = graphql`
	fragment BlogCard on ContentfulPageBlogPost {
		id
		slug
		title
		description
		node_locale
		date_de: createdAt(formatString: "DD. MMMM", locale: "de")
		date_en: createdAt(formatString: "MMMM DD", locale: "en")
		date: createdAt(formatString: "YYYY-MM-DD")
		keyVisual {
			fluid(resizingBehavior: FILL, cropFocus: CENTER, maxWidth: 600, maxHeight: 400, quality: 80) {
				...GatsbyContentfulFluid_withWebp
			}
		}
		author {
			name
			profilePicture {
				fluid(maxHeight: 72, maxWidth: 72, resizingBehavior: THUMB, cropFocus: FACE, quality: 80) {
					...GatsbyContentfulFluid_withWebp
				}
			}
		}
	}
`

export default ModuleBlogCard