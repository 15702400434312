import React from 'react'
import Wave3 from '../shapes/wave-3'

const HeroTeaserText = ({ children }) => {
  return (
    <section className="position-relative pt-6 pb-6 pt-sm-8 pt-lg-10">
      <div className="shape shape-top shape-fluid-x shape-no-scale svg-shim">
        <Wave3 style={{ color: '#E2FCF0' }} />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            {children}
          </div>
        </div>
      </div>
    </section>
  )
}

export default HeroTeaserText