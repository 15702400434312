import React from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage, useIntl } from 'react-intl';

import BlogCard from '../components/modules/blog-card'
import HeroTeaserText from '../components/modules/hero-teaser-text'
import SEO from '../components/modules/seo/seo';

const BlogOverview = ({ data, pageContext: { slug, locale } }) => {

  const intl = useIntl()
  const posts = data.allPosts.nodes

  return (
    <div className="page-margin bg-light">
      <SEO
        title={intl.formatMessage({ id: "blogOverviewHeadline" })}
        description={intl.formatMessage({ id: "blogOverviewMetaDescription" })}
        slug={slug}
        locale={locale}
      />
      <HeroTeaserText>
        <h6 className="text-uppercase font-weight-bold ml-px">
          Blog
          </h6>
        <h1 className="display-2 font-weight-bold text-primary">
          <FormattedMessage id="blogOverviewHeadline" />
        </h1>
        <p className="lead mb-0">
          <FormattedMessage id="blogOverviewSubline" />
        </p>
      </HeroTeaserText>
      <FeaturedPosts>
        {posts.map((post, i) => (
          <BlogCard
            data={post}
            isLarge={i < 2}
            key={post.id}
          />
        ))}
      </FeaturedPosts>
    </div>
  )
}

const FeaturedPosts = ({ children }) => {
  return (
    <section className="pt-7">
      <div className="container">
        <div className="row justify-content-center pb-4">
          <div className="col-12 col-lg-8">
            <h2 className="mb-0">
              <FormattedMessage id="popularPostsHeadline" />
            </h2>
            <p className="mb-5 text-muted">
              <FormattedMessage id="popularPostsSubline" />
            </p>
          </div>
        </div>
        <div className="row">
          {children}
        </div>
      </div>
    </section>
  )
}


export const query = graphql`
	query AllBlogPosts($locale: String!) {
		allPosts: allContentfulPageBlogPost(sort: {fields: createdAt, order: DESC}, filter: {node_locale: {eq: $locale}}) {
			nodes {
				id
				slug
				title
				description
				node_locale
				date_de: createdAt(formatString: "DD. MMMM", locale: "de")
				date_en: createdAt(formatString: "MMMM DD", locale: "en")
				date: createdAt(formatString: "YYYY-MM-DD")
				keyVisual {
					fluid(resizingBehavior: FILL, cropFocus: CENTER, maxWidth: 600, maxHeight: 400, quality: 80) {
						...GatsbyContentfulFluid_withWebp
					}
				}
				author {
					name
					profilePicture {
						fluid(maxHeight: 72, maxWidth: 72, resizingBehavior: THUMB, cropFocus: FACE, quality: 80) {
							...GatsbyContentfulFluid_withWebp
						}
					}
				}
			}
		}
	}
`

export default BlogOverview