import React from 'react'

export default props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 1090" fill="none" width="100%" height="100%" {...props}>
    <path d="M0 352C315 352 518 5 849 5c356 0 591 121 591 121v996H0V352z" fill="url(#gradientToTransparent)" />
    <path d="M1440 0H0v352c156 0 284-85 414-170C547 94 681 5 849 5c356 0 591 121 591 121V0z" fill="#fff" />
    <defs>
      <linearGradient id="gradientToTransparent" x1="720" y1="180" x2="720" y2="1224" gradientUnits="userSpaceOnUse">
        <stop stopColor="currentColor" />
        <stop offset="1" stopColor="currentColor" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)


